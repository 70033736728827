<template>
  <div class="app flex-row align-items-center">
    <loading :active.sync="isLoading"></loading>
  </div>
</template>

<script>
export default {
  name: "logout",
  data() {
    return {
      isLoading: true
    };
  }
};
</script>
